import './App.css';
import NavBar from './Comps/NavBar.js';
import HomePage from './Comps/HomePage.js';
import Login from './Comps/Login';
import Register from './Comps/Register';
import {
  createBrowserRouter,
  RouterProvider,
  useLoaderData,
} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <NavBar/>
      <HomePage/>
      
       <Login></Login>
       <Register/>
    </div>
  );
}

export default App;
