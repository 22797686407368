import './NavBar.css'

function NavBar() {
    return (
      <div className="NavBar">

        <div className="navPrinc">


            <div className="navSX">
                <span class="material-symbols-outlined">
                location_on
                </span>
                Roma
            </div>

            <div className="navCN">
                <h1 className="scrittaLogo">Zampify</h1>
                <h2 className="scrittaNav">Cani</h2>
                <h2 className="scrittaNav">Gatti</h2>
                <h2 className="scrittaNav">Chi siamo ?</h2>
                <h2 className="scrittaNav">Sono un allevatore</h2>
            </div>

            <div className="navDX">
                <h2 className="scrittaNav">Login</h2>
                <span class="material-symbols-outlined user">person</span>
                <span class="material-symbols-outlined fav">favorite</span>
            </div>

        </div>


        <div className="navSec">
            <div className="scritteNavSx">
                <h3 className="scrittaNavS">Cani</h3>
                <h3 className="scrittaNavS">Gatti</h3>
                <h3 className="scrittaNavS">Chi siamo ?</h3>
            </div>
            <div className="scritteNavDx">
                <h3 className="scrittaNavS">Contattaci</h3>
                <h3 className="scrittaNavS">Sono un allevatore</h3>
            </div>
        </div>

      </div>
    );
  }
  
  export default NavBar;