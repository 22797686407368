import './card.css'

function Card(props){
    let {titolo,descrizione,peso,sesso,prezzo,sconto} = props.props;
    const imgLink = "https://statickodami.akamaized.net/wp-content/uploads/sites/31/2020/12/Schermata-2020-12-08-alle-10.36.58.jpg";

    return <>
        <div className="card">
            <div alt="" className="imgCard" >
                <img src={imgLink} alt="" className='imgCardIMG' />
            </div>

            <div className="cardDX">
                <h3 className="titoloCard">{titolo}</h3>
                <h3 className="descrizioneCard">{descrizione}</h3>
                <div className="boxInfo">
                    <div className="infoItem">
                        <span class="material-symbols-outlined itemIcons">weight</span>
                        {peso + "KG"}
                    </div>
                    <div className="infoItem">
                        <span class="material-symbols-outlined itemIcons">euro</span>
                        {prezzo + ".00"}
                    </div>
                    <div className="infoItem">
                        <span class="material-symbols-outlined itemIcons">sell</span>
                        {sconto + "% OFF"}
                    </div>
                </div>
                <div className="bottiniCard">
                    <button className="bottone1Card">Adottami!</button>
                    <button className="bottone2Card">
                        <span class="material-symbols-outlined favIco">favorite</span>
                    </button>
                </div>
            </div>
        </div>
    </>
}
export default Card;