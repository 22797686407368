import './HomePage.css'
import './HomePagep2.css'
import snf from './snf.png'
import Card from './Card'

function HomePage(){
    return(
        <div className='HomePage'>
            <P1></P1>
            <P2></P2>
        </div>
    );
}

function P1(){
    return(
        <>
        <div className='sfondo'>
                <svg id ="onde" viewBox="0 0 1728 814" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M467 780.585C309.203 768.502 158.219 657.574 0 654V0H1728V701.167C1580.27 728.322 1444.89 813.624 1291 813.085C1126.17 812.508 1048.2 709.72 883.5 703.085C718.189 696.426 631.962 793.217 467 780.585Z" fill="var(--col2)" transform="matrix(1, 0, 0, 1, -1.4210854715202004e-14, 0)"/>
                </svg>

            </div>
                <div id='compSf'>
                    <img src={snf}  alt="" className="compSfIMG" />
                </div>

            <div className="searchBar">

                <div className="bar1">
                    Cerca 
                    <span class="material-symbols-outlined">search</span>
                </div>

                <div className="bar2">
                    Seleziona razza
                    <span class="material-symbols-outlined">expand_more</span>
                </div>

                <div className="bar3">
                    Età
                    <span class="material-symbols-outlined">expand_more</span>
                </div>

                <button className="bar4">Cerca</button>
            </div>

            <h2 className="scrittaBigBig">
                Il tuo cucciolo <br /> a casa tua 
            </h2>

            <h2 className="scrittaBig">
            Fai il test e grazie all'AI scopri<br /> il tuo match perfetto
            </h2></>
    );
}


function P2(){
    let a = {titolo : "Golder Retriver"
    ,descrizione : "Dobby è un Golden Retiver di dimensioni medie E' molto affettuoso e non vede l'ora di conoscerti !"
    ,peso : "20"
    ,sesso : "m"
    ,prezzo : "200"
    ,sconto : "0"};

    let b = [a,a,a,a,a]
    return(
        <div className='p2'>
            <h3 className="scrittaB">Cuccioli in primo piano</h3>
            <h3 className="scrittaN">Abbiamo selezionato dei cuccioli in primo piano intorno a te!</h3>
            
            <div className="cardWrapWrap">
            <div className="copriEl">
                <div className="copriEl1"></div>
                <div className="copriEl2"></div>
            </div>

                <div className='cardWrap'>

                    {b.map((e)=>{
                        return (
                            
                            <Card 
                            props = {e}
                            ></Card>
                            
                            );
                        })}

                </div>

            </div>

        </div>
    )
}
export default HomePage;