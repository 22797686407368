import { useState } from 'react';
import './Auth.css'

function Login(){

    const [pass,setPass] = useState();
    const [em,setEm] = useState();

    const accedi = ()=>{
        const data = new URLSearchParams();

        data.set("email", em);
        data.set("password", pass);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: data
        };
        console.log(em);
        console.log(requestOptions);
        fetch('http://api.zampify.it/login', requestOptions)
            .then(response => {
                console.log("DIO");
                console.log(response.headers.get('set-cookie'));
                console.log(response.headers.getSetCookie());
                return response.text();
            })
            .then(data => console.log(data));
    }
    

    return(
        <div className="loginPageWrap">

            <div className="loginPage">
                <h2 className="scrittaB">Login</h2>
                <input type="email" className="inputUn" placeholder="Email" onChange={(e)=>setEm(e.target.value)}/>
                <input type="password" className="inputPw" placeholder="Password" onChange={(e)=>setPass(e.target.value)}/>
                <button className="inputBt" onClick={accedi}>Login</button>
            </div>

        </div>
    )
}

export default Login;