import { useState } from 'react';
import './Auth.css'

function Register(){

    const [firstName,setFirstName] = useState();
    const [lastName,setLastName] = useState();
    const [em,setEm] = useState();
    const [pass,setPass] = useState();
    const [passCon,setPassCon] = useState();

    const registrati = ()=>{
        if (setPass != setPassCon)
            return;

        const data = new URLSearchParams();

        data.set("fName", firstName);
        data.set("lName", lastName);
        data.set("email", em);
        data.set("password", pass);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
            body: data
        };
        fetch('http://api.zampify.it/register', requestOptions)
            .then(response => response.json())
            .then(data => {});
    }
    

    return(
        <div className="registerPageWrap">

            <div className="registerPage">
                <h2 className="scrittaB">Registrati</h2>
                <input type="text" className="inputUn" placeholder="Nome" onChange={(e)=>setFirstName(e.target.value)}/>
                <input type="text" className="inputUn" placeholder="Cognome" onChange={(e)=>setLastName(e.target.value)}/>
                <input type="email" className="inputUn" placeholder="Email" onChange={(e)=>setPass(e.target.value)}/>
                <input type="password" className="inputPw" placeholder="Password" onChange={(e)=>setEm(e.target.value)}/>
                <input type="password" className="inputPw" placeholder="Conferma Password" onChange={(e)=>setPassCon(e.target.value)}/>
                <button className="inputBt" onClick={registrati}>Registrati</button>
            </div>

        </div>
    )
}

export default Register;